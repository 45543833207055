@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  /* width: 100%; */
  margin: 0;
  background: transparent !important;
  border: none !important;
  /* height: 100%; */
  inset: 0 !important;
  align-items: center;
  justify-content: center;
  display: flex;
  position: static !important;
  height: auto !important;
  padding: 0 20px !important;
}

.opt-input:focus {
  border: 1px solid #fff !important;
}

.tst {
  background: #444;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  letter-spacing: 0.5px;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  white-space: nowrap;
}

body {
  width: 100%;
  height: 100%;
  background: rgb(6, 14, 36);
}

input {
  outline: none;
}

input:focus {
  border: 1px solid #fff;
}

.model-cover {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.model-content {
  width: calc(100% - 40px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
